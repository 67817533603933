import React, { useState, useEffect } from 'react';
import './global.css';
import './product.css';
import './join.css'; // Create a new CSS file for join page styles
import rickshaw1001a from './assets/product/logo_rickshaw_front_rename_360.png';

const GOOGLE_SHEET_WEB_APP_URL = 'https://script.google.com/a/macros/kopper.ai/s/AKfycbyTK_I1_UF1H59K1hV8BbRJARXCdetur6fEy2qJnOOhQYvrhJUTXzrYnaTyDSU_pahq/exec'; // Replace with your Google Apps Script URL

const JoinComponent = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    generateGrid();
  }, []);

  const randomColor = () => { 
    const color1 = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    const color2 = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    const angle = Math.floor(Math.random() * 360);
    return `linear-gradient(${angle}deg, ${color1}, ${color2})`;
  };

  const generateRandomId = () => { return Math.floor(Math.random() * 9000000000) + 1000000000; };
  
  const [randomId] = useState(generateRandomId());


  const generateGrid = () => {
    const newGrid = [];
    for (let row = 0; row < 18; row++) {
      const currentRow = [];
      for (let col = 0; col < 18; col++) {
        currentRow.push(<div style={{
          width: '20px',
          height: '20px',
          background: randomColor(),
          display: 'inline-block'
        }} key={`${row}-${col}`} />);
      }
      newGrid.push(<div key={row} style={{ display: 'flex' }}>{currentRow}</div>);
    }
    setGrid(newGrid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(GOOGLE_SHEET_WEB_APP_URL, {
        method: 'POST',
        mode: 'no-cors', // Google Apps Script requires no CORS
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      alert('Thank you for joining! Your information has been submitted.');
      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        message: ''
      });
      generateGrid(); // Optionally refresh the grid
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your information. Please try again.');
    }
  };

  return (
    <div className='pane join-page'>
      <div className="product" style={{ position: 'relative' }}>            
        <img src={rickshaw1001a} alt="Rickshaw 1001 front" width="360px" className="rickshaw1001a" />
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          flexWrap: 'wrap',
          opacity: 0.42
        }}>
          {grid}
        </div>  
      </div>

      <div className="join-form-container">
        <h2>Join Our Club</h2>
        <form onSubmit={handleSubmit} className="join-form">
          <label>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
            ></textarea>
          </label>
          <button type="submit" className='submit-button'>Submit</button>
        </form>
      
      </div>
    </div>
  );
};

export default JoinComponent;
