import React, { useState } from 'react';
import myImage from './assets/logo_rickshaw_matrix_transparent_3000x3000.png'; // Adjust path as needed

const ArtistsComponent = () => {
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [isSquareBorder, setIsSquareBorder] = useState(true);
  const [isGradientCircles, setIsGradientCircles] = useState(false);

  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleBorderStyleChange = (e) => {
    setIsSquareBorder(e.target.value === '1');
  };

  const handleGradientCirclesChange = (e) => {
    setIsGradientCircles(e.target.value === '1');
  };

  return (
    <div className='pane artists' style={{ backgroundColor }}>
      <div className={`container-about ${isSquareBorder ? 'square-border' : 'triangle-border'}`}>
        
        <div className={`image-container ${isGradientCircles ? 'gradient-circle' : ''}`}>
          <img src={myImage} alt="Rickshaw Club Artwork" />
        </div>

        {/* Sliders */}
        <div className="sliders">
          <label>
            Background Color:
            <input type="color" value={backgroundColor} onChange={handleBackgroundColorChange} />
          </label>

          <label>
            Border Style:
            <input
              type="range"
              min="0"
              max="1"
              step="1"
              onChange={handleBorderStyleChange}
              value={isSquareBorder ? '1' : '0'}
            />
            {isSquareBorder ? 'Square' : 'Triangle'}
          </label>

          <label>
            Gradient Circles:
            <input
              type="range"
              min="0"
              max="1"
              step="1"
              onChange={handleGradientCirclesChange}
              value={isGradientCircles ? '1' : '0'}
            />
            {isGradientCircles ? 'Gradients On' : 'Gradients Off'}
          </label>
        </div>
      </div>    
    </div>
  );
};

export default ArtistsComponent;
