import React, { useState } from 'react';
import './global.css'; // Assuming your global CSS styles are here

const importAll = (requireContext) => {
  return requireContext.keys().map(requireContext);
};

const categories = {
  all: importAll(require.context('./assets/gallery', true, /\.(png|jpe?g|svg)$/)),
  bags: importAll(require.context('./assets/gallery/bags', false, /\.(png|jpe?g|svg)$/)),
  longsleeve: importAll(require.context('./assets/gallery/longsleeve', false, /\.(png|jpe?g|svg)$/)),
  hoodies: importAll(require.context('./assets/gallery/hoodies', false, /\.(png|jpe?g|svg)$/)),
  sweatshirts: importAll(require.context('./assets/gallery/sweatshirts', false, /\.(png|jpe?g|svg)$/)),
};

const randomizeImages = (images) => {
  return images.sort(() => Math.random() - 0.5); // Shuffle the images
};

const GalleryPane = () => {
  const [activeCategory, setActiveCategory] = useState('all'); // Default to "all"
  const [images, setImages] = useState(randomizeImages(categories['all']));

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    if (category === 'all') {
      setImages(randomizeImages(categories[category]));
    } else {
      setImages(categories[category]); // Load images from the selected category
    }
  };

  return (
    <div>
      <div className="category-buttons">
        <button
          className={`filter-button ${activeCategory === 'all' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('all')}
        >
          All
        </button>
        <button
          className={`filter-button ${activeCategory === 'bags' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('bags')}
        >
          Bags
        </button>
        <button
          className={`filter-button ${activeCategory === 'longsleeve' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('longsleeve')}
        >
          Longsleeve
        </button>
        <button
          className={`filter-button ${activeCategory === 'hoodies' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('hoodies')}
        >
          Hoodies
        </button>
        <button
          className={`filter-button ${activeCategory === 'sweatshirts' ? 'active' : ''}`}
          onClick={() => handleCategoryChange('sweatshirts')}
        >
          Sweatshirts
        </button>
      </div>

      <div className="gallery-container">
        {images.map((image, index) => {
          const imageName = image.split('/').pop().split('.')[0]; // Extract file name
          return (
            <div className="gallery-item" key={index}>
              <img src={image} alt={imageName} className="gallery-image" />
              <p className="gallery-description">{imageName.replace(/_/g, ' ')}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GalleryPane;
