import React, { useState } from 'react';
import './bespoke.css';

const BespokeComponent = () => {
  // State for the hue, saturation, and lightness sliders
  const [hueRed, setHueRed] = useState(0); // For the "red" hue
  const [saturation, setSaturation] = useState(100); // For the saturation slider
  const [lightness, setLightness] = useState(50); // For the lightness slider

  // Function to combine hue, saturation, and lightness into a color
  const getColor = () => {
    return `hsl(${hueRed}, ${saturation}%, ${lightness}%)`;
  };

  return (
    <div className='pane bespoke'>

      <div className="bespoke-canvas">
        <h3>BESPOKE RESERVATIONS</h3>
        <canvas
          id="productCanvas"
          width="300"
          height="400"
          style={{ backgroundColor: '' }}
        >
          
        </canvas>
      </div>

     

      {/* Pane Options */}
                <div className='paneOptions'>
                <button className='choiceBTNs' onClick={() => window.location.href='https://donate.stripe.com/3csbL61bK910emAdQT'}> BESPOKE </button>
                <button className='choiceBTNs' onClick={() => window.location.href='https://book.stripe.com/5kA4iE6w40uuguIcMR'}> LONG SLEEVE TEE </button> 
                <button className='choiceBTNs' onClick={() => window.location.href='https://book.stripe.com/bIY2aw6w42CC5Q43ci'}> HOODIE </button>    
              </div>
    </div>
  );
};

export default BespokeComponent;
